import {Link} from 'react-router-dom'
import './MobileNavBotton.css'
import { MdEventNote, MdOutlinePhoneInTalk } from "react-icons/md";
import { FaVideo, FaWhatsapp } from "react-icons/fa";

import React, { Fragment, useEffect, useState } from 'react'

const MobileNavBottom = () => {
  const [VirtualLink, setVirtualLink] = useState('');
  useEffect(()=>{
    const currentHour = new Date().getHours();
    console.log(currentHour, 'current hour')
    if(currentHour >= 10 && currentHour <=18){
      setVirtualLink('https://us02web.zoom.us/j/81925218923')
    }else{
      setVirtualLink('https://globedwise.zohobookings.com/#/studyabroadonlinecounseling')
    }
  },[])
  return (
    <Fragment>
      <div className="mobile-nav bg-[#000000]">
        <div className="mobile-nav__menu">
          <ul>
            <li>
              <Link to="https://globedwise.com/events" target='_none'>
                <MdEventNote />
                <span>Events</span>
              </Link>
            </li>
            <li>
              <Link to="tel:+919696875875">
                <MdOutlinePhoneInTalk />
                <span>Talk</span>
              </Link>
            </li>
            <li>
              <Link to={VirtualLink}>
                <FaVideo />
                <span>Go Virtual</span>
              </Link>
            </li>
            <li>
              <Link to="https://wa.link/8lo9sp">
                <FaWhatsapp />
                <span>WhatsApp</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default MobileNavBottom