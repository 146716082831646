import "./App.css";
import "./assets/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import Ielts from "./components/ielts";
import NewHeadercopy from "./components/includes/NewHeadercopy";
import Mobileheadernew from "./components/includes/Mobileheadernew";
import Footer from "./components/includes/Footer";
import Thankyoupage from "./components/Thankyoupage";
import Checkielts from "./components/zoho/Checkielts";
import OnlineCounseling from "./components/OnlineCounseling";
import Salescall from "./components/Salescall";
import Salesvideo from "./components/Salesvideo";
import Infopopup from "./components/Infopopup";
import MainHeader from "./components/includes/MainHeader";
import StickyContactButton from "./components/StickyContactButton";
import MobileNavBottom from "./components/MobileNavBottom";


function App() {
  return (
    <>
      <Router>
        {/* <NewHeadercopy /> */}
        {/* <Mobileheadernew /> */}
        <MainHeader />
        <Routes>
          <Route path="/" element={<Ielts />}></Route>
          <Route path="/thankyou" element={<Thankyoupage />}></Route>
          <Route path="/level-test" element={<Checkielts />}></Route>
          <Route
            path="/onlinecounseling"
            element={<OnlineCounseling />}
          ></Route>
        </Routes>
        {/* <Infopopup/> */}
        <MobileNavBottom/>
        <Footer />
        {/* <Salescall /> */}
        {/* <StickyContactButton /> */}
        {/* <Salesvideo/> */}
      </Router>
    </>
  );
}

export default App;
